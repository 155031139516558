<template>
  <div @click="generate">
    <slot>
      <CButton color="danger">{{ 'Generate Excel' }}</CButton>
    </slot>
  </div>
</template>

<script>
//import XLSX from 'xlsx'
//import XLSX from 'xlsx-style'
import XLSX from 'xlsx-js-style'

export default {
  name: 'ExcelGenerator',
  props: {
    data: {
      type: Array,
      required: true
    },
    fields: {
      type: Object,
      required: true
    },
    formulas: {
      type: Object
    },
    worksheet: {
      type: String
    },
    name: {
      type: String
    },
    email: {
      type: Boolean
    }
  },
  data() {
    return {
      wscols: [],
      json_data: {}
    }
  },
  methods: {
    getColumnNames(max_pos) {
      const next_char = c => (c ? String.fromCharCode(c.charCodeAt(0) + 1) : 'A')
      const next_col = s => s.replace(/([^Z]?)(Z*)$/, (_, a, z) => next_char(a) + z.replace(/Z/g, 'A'))

      const column_names = []
      for (let i = 1, s = ''; i <= max_pos; i++) {
        s = next_col(s)
        column_names.push(s)
      }
      return column_names
    },

    generate() {
      const workbook = XLSX.utils.book_new()
      const worksheet = XLSX.utils.aoa_to_sheet(this.parseData())
      if (this.wscols.length) worksheet['!cols'] = this.wscols

      XLSX.utils.book_append_sheet(workbook, worksheet, (this.worksheet || 'SheetJS').replace(/[\W_-]/g, '-'))

      //XLSX.writeFile(wb, this.name) // TODO: type ?*/
      if (this.email) {
        return XLSX.write(workbook, { type: 'base64', bookType: 'xlsx', bookSST: false }) // [ file, buffer, binary, base64 ]
      } else {
        XLSX.writeFile(workbook, this.name)
      }
    },

    generateEmail() {
      return this.generate(true)
    },

    parseFormula(formula, fields, column_names) {
      if (!formula) return null
      for (const column in column_names) {
        formula = formula.replaceAll(`[${fields[column].field}]`, `${column_names[column]}`) // TODO: improve this
      }
      return formula
    },

    parseData() {
      let row = 2 // Dynamic position!!!
      const fields = Object.values(this.fields)
      const excel_data = [Object.keys(this.fields)]
      const column_names = this.getColumnNames(fields.length)
      this.wscols = excel_data[0].map(field => ({ wch: field.length, f: this.parseFormula(this.fields[field].formula, fields, column_names) })) // TODO: Check column formula ???

      for (const data_row of this.data) {
        let col = 0
        const new_row = []
        for (const field of fields) {
          const index = typeof field === 'string' ? field : field.field
          let value = data_row[index] || field.default
          if (field.formula) {
            value = { f: this.wscols[col].f.replaceAll(':0', `${row}`) }
          } else {
            this.wscols[col].wch = this.compareValues(this.wscols[col].wch, ('' + value).length)
            if (field.callback) value = field.callback(value)
            value = { v: value }
          }
          if (field.style) value.s = field.style
          if (field.formatter) value.t = field.formatter.substring(0, 1)
          new_row.push(value)
          col++
        }
        row++
        excel_data.push(new_row)
      }
      return excel_data
    },

    compareValues(a, b) {
      return a > b ? a : b
    }
  }
}
</script>
